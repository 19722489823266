import React from 'react';
import './InputTypes.css';
import TimezoneSelect from "react-timezone-select"

type Props = {
  label: string;
  value: string | undefined | null,
  error: string | undefined | null,
  onChange: (value: string) => void;
  disabled?: boolean,
}

export const LabeledTimeZoneInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>

    <TimezoneSelect
        isDisabled={props.disabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 0,
            padding: 0,
            margin: 0,
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            padding: 12,
          }),
        }}
        classNames={{
          // input: (s) => 'TimeZoneInputLabel',
          container: (s) => 'LabeledTextInput',
        }}
        value={props.value || ''} onChange={v => props.onChange(v.value)}/>

    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>
}