import React from 'react';
import './Onboarding.css';
import {CountryDropdown} from "react-country-region-selector";
import {FieldWithError} from "../../../components/input/InputTypes";

type Props = {
  value: FieldWithError<boolean>;
  onChange: (value: boolean) => void;
}

export const AcceptTosInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'CreateProfileCheckboxContainer'}>
      <input type={'checkbox'} className={'CreateProfileCheckbox'}
             checked={props.value.value || false}
             onChange={e => {
               props.onChange(e.target.checked)
             }}/>
      <span>
      I agree to the&nbsp;
        <a className={'BrownText'} target={'_blank'} href={'https://kenko.space/privacy'}>Privacy Policy</a>,&nbsp;
        <a className={''} target={'_blank'} href={''}>Terms of Service</a> and&nbsp;
        <a className={''} target={'_blank'} href={''}>Terms of Business</a>.
      </span>
    </div>
    {props.value.error && <div className={'LabeledInputError'}>{props.value.error}</div>}
  </div>;
}