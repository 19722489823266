/* global google */

import {Routes, Route} from "react-router";
import React, {useContext} from 'react';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import {ProviderProfileContext} from "../ProviderProfileProvider";
import {Header} from "./Header";
import {ProviderHome} from "./Home/ProviderHome";
import {RedeemBusinessInvite} from "./RedeemBusinessInvite/RedeemBusinessInvite";
import {RedeemTeamMemberInvite} from "./RedeemTeamMemberInvite/RedeemTeamMemberInvite";
import {getBookingHost} from "../util/HostUtils";

/**
 * This is the parent of everything on the site. It acts as a login gate
 * so we only allow people in the whitelist. If the user is not logged
 * in they are prompted to log in.
 *
 * If they're not in the whitelist they are just not permitted.
 *
 * If they're in, then the rest of the app renders.
 */
export const App = () => {

  return (
      <div className="App">
        <Header/>
        <Body/>
      </div>
  );
}

/**
 * This is extracted from above so I can do if/else clauses for all the possible
 * options rather than {!profileError && !profileLoading && profile && ..} nonsense.
 */
const Body = () => {

  const {isLoading, user, loginWithRedirect} = useAuth0();
  const {provider, profileError, profileLoading} = useContext(ProviderProfileContext);

  const auth0Login = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      },
      authorizationParams: {
        audience: `https://narwhal.kenko.space/`,
        scope: 'openid offline_access' // to get refresh token
      }
    });
  }

  if (isLoading) {
    return <div>Loading user...</div>
  }

  if (!user) {
    return <div>
      <h2>Kenko for Business</h2>
      Create an account or log in to manage your business.
      <div className={'Flex1 HorizontalFlex'} style={{margin: 30}}>
        <button className={'ActionButton'} onClick={auth0Login}>Continue</button>
      </div>

      <div className={'BrownText'} style={{margin: 20}}>
        Are you looking to <a href={getBookingHost()}>book an appointment</a>?
      </div>
    </div>
  }


  if (profileLoading) {
    return <div>Loading profile...</div>;
  }

  if (profileError) {
    return <div style={{color: 'red'}}>{profileError}</div>;
  }

  if (!provider) {
    return <Routes>
      <Route path="/redeemInvite/:inviteCode" element={<RedeemBusinessInvite/>}/>
      <Route path="/providerInvite/:providerId/:inviteCode" element={<RedeemTeamMemberInvite/>}/>
      <Route path="*" element={<div>
        We're sorry but we don't see a provider profile for this login.
        <br/><br/>
        <a href={'mailto:info@kenko.space'}>Contact us</a> if you're interested in becoming a kenko provider
      </div>}/>
    </Routes>
  }

  // All is good! There are sub-routes in ProviderHome.
  return <ProviderHome/>
}

export default App;
