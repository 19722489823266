import {DateDiv} from "./DateDiv";
import React from "react";
import './BookingOverlay.css';
import {space} from "./compiled";

interface Props {
  service?: space.kenko.proto.IServiceProto,
  address?: space.kenko.proto.IAddressProto,
  availableTimes: Date[],

  onCancel?: () => void;
  onTimeChosen?: (timestamp: number) => void;
}

export const AppointmentSlots = (props: Props) => {

  // The response is just a list of numbers. This categorizes them by date
  // in the local time zone.
  const splitByDate = (dates: Date[] | undefined): Date[][] => {
    if (!dates) {
      return [];
    }

    let subarrays: Date[][] = [];
    let startIndex = 0;
    for (let i = 0; i < dates.length; i++) {

      if (dates[i].getDate() != dates[startIndex].getDate()) {

        // This position in the array has a different date than the last position
        // in the array. Add a new subarray to the return value.
        // Note this is a shallow copy which is very good!
        subarrays.push(dates.slice(startIndex, i));
        startIndex = i;
      }
    }

    if (startIndex < dates.length) {
      subarrays.push(dates.slice(startIndex));
    }

    return subarrays;
  }


  const dateSubArrays = splitByDate(props.availableTimes);

  return <>
    {dateSubArrays.map(dateSubArray => {
      return <DateDiv dates={dateSubArray}
                      providerId={'todo provider id'}
                      onTimeSelected={time => {
                        props.onTimeChosen && props.onTimeChosen(time);
                      }}
      />
    })}
  </>;
}