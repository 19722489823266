import React from 'react';
import './InputTypes.css';
import {CountryDropdown} from "react-country-region-selector";
import {FieldWithError} from "../input/InputTypes";

type Props = {
  label: string;
  value: FieldWithError<string>;
  onChange: (value: string) => void;
}

export const LabeledCountryInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>
    <CountryDropdown classes={'LabeledTextInput'}
                     valueType='short'
                     onChange={props.onChange}
                     value={props.value.value || ''}/>
    {props.value.error && <div className={'LabeledInputError'}>{props.value.error}</div>}
  </div>
  
}