import React from 'react';
import './BookingOverlay.css';
import {BookingOverlayProps} from "./BookingOverlay.types";
import {AppointmentSlots} from "./AppointmentSlots";

export const BookingOverlayApp: React.FC<BookingOverlayProps> = (props) => {
  return (<>
        <div className={'Overlay'}>
          <div className={'OverlayContent'}>
            <div className={'BookingOverlayHeader BookingOverlaySectionPadding'}>
              <div style={{flex: 1}}><h1>Book Appointment</h1></div>
              <div style={{flex: 0, width: 200}}>
                <button className='ActionButton' onClick={props.onClose}>Close</button>
              </div>
            </div>
            <div className={'BookingOverlayBottom'}>
              <div className={'BookingOverlayBottomLeft BookingOverlaySectionPadding'}>
                <span><b>{props.service.name} - ({props.service.lengthMinutes} min) - FREE</b></span>
                <span>Virtual meeting</span>
              </div>
              <div className={'BookingOverlayBottomRight BookingOverlaySectionPadding'}>
                <AppointmentSlots availableTimes={props.timeSlots}/>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
