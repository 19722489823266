import React from 'react';
import './InputTypes.css';
import {CountryDropdown} from "react-country-region-selector";
import {FieldWithError} from "../input/InputTypes";

type Props = {
  label: string;
  helpText: string;
  value: FieldWithError<boolean>;
  onChange: (value: boolean) => void;
}

export const LabeledYesNoInput = (props: Props) => {

  return <div className={'VerticalFlex'}>
    <div className={'LabeledTextInputLabel'}>{props.label}</div>
    <input className={'CreateProfileCheckbox'}
        type={'checkbox'} checked={props.value.value || false}
           onChange={(e) => {props.onChange(e.target.checked)}}/>
    {props.helpText && <div className={'InputHelpText'}>{props.helpText}</div>}
    {props.value.error && <div className={'LabeledInputError'}>{props.value.error}</div>}
  </div>
  
}