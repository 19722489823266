import React, {useEffect, useState} from 'react';
import {
  CreateOrUpdateTeamMemberRequest,
  CreateOrUpdateTeamMemberResponse,
  GenerateLoginInviteRequest,
  GenerateLoginInviteResponse,
  GetLoginsForBusinessRequest,
  GetLoginsForBusinessResponse,
  IApiProviderLoginProto,
  space
} from "../../../../provider_api";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../../rpcSender";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import {filterErrors, findError} from "../../../../components/input/InputTypes";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {LabeledPhoneInput} from "../../../../components/input/LabeledPhoneInput";
import {RpcState} from "../../../../RpcState";
import {EditableComponentProps, EditableList} from "../../../../components/input/EditableList";
import {EducationInputComponent} from "./EducationInputComponent";
import {PhotoUpload} from "../../../../components/input/PhotoUpload";
import {LicenseCredentialComponent} from "./LicenseCredentialComponent";
import {findProvider} from "../Calendar/CalendarUtil";
import {LabeledYesNoInput} from "../../../../components/input/LabeledYesNoInput";
import ProviderBioProto = space.kenko.proto.ProviderBioProto;
import EducationProto = space.kenko.proto.EducationProto;
import IEducationProto = space.kenko.proto.IEducationProto;
import LicenseOrCredentialsProto = space.kenko.proto.LicenseOrCredentialsProto;
import ILicenseOrCredentialsProto = space.kenko.proto.ILicenseOrCredentialsProto;
import IProviderBioProto = space.kenko.proto.IProviderBioProto;
import IErrorProto = space.kenko.proto.IErrorProto;
import {rpc} from "protobufjs";


// This is the page for editing (or adding) a service.
export const AddOrUpdateTeam = () => {

  // If editing, the provider id is a param. 
  const {providerIdParam} = useParams();
  const isEditing = !!providerIdParam;

  // Login info is loaded via a separate RPC. This lives outside the profile
  const [login, setLogin] = useState<IApiProviderLoginProto | null>();

  const [rpcState, setRpcState] = useState<RpcState>({});
  const [saveTime, setSaveTime] = useState<Date>();

  // A separate rpc for fetching the user's login info
  const [loginRpcState, setLoginRpcState] = useState<RpcState>({});


  // These are fatal RPC errors that are so bad that the profile didn't
  // even save. These happen when adding a new team member, but you didn't
  // add a name or an email address.
  const [rpcErrors, setRpcErrors] = useState<IErrorProto[]>([]);

  const {businessProfile, setBusinessProfile} = useProviderProfile();
  const {getIdTokenClaims} = useAuth0();

  const [genInviteRpcState, setGenInviteRpcState] = useState<RpcState>({});
  const [inviteCode, setInviteCode] = useState<string>();

  // This is the working copy of the provider's bio. It is initialized to the value
  // from props but can change as it gets edited..
  const [bio, setBio] = useState<IProviderBioProto>(new ProviderBioProto());

  const navigate = useNavigate();

  useEffect(() => {
    // Update the provide if it changed on the server
    setBio(findProvider(businessProfile?.providers, providerIdParam) || new ProviderBioProto())
  }, [businessProfile]);

  useEffect(() => {
    // You don't need to fetch login info when adding a new person
    if (!providerIdParam) {
      setRpcState({state: 'none'});
      return;
    }

    const request = GetLoginsForBusinessRequest.encode(new GetLoginsForBusinessRequest({
      providerIds: [providerIdParam]
    })).finish();

    setLoginRpcState({state: 'pending'});
    SendRpc(getIdTokenClaims, 'get_logins', request)
        .then(r => {
          const response = GetLoginsForBusinessResponse.decode(r);
          if (!response.okay) {
            setLoginRpcState({state: 'error', message: 'unknown error fetching login'});
            return;
          }

          if (response.provider.length > 0) {
            if (response.provider[0].providerId != providerIdParam) {
              console.log('Error: provider ids not matching!');
              setLoginRpcState({state: 'error', message: 'wrong provider id loaded!'});
              setLogin(null);
              return;
            }

            setLogin(response.provider[0])
            setLoginRpcState({state: 'success'});
            setSaveTime(new Date());
            return;
          }

          // Success but there was no login found.
          setLogin(null);
          setLoginRpcState({state: 'success'});

        })
        .catch(e => {
          setLoginRpcState({state: 'error', message: e});
        })
  }, []);

  const generateInvite = () => {
    // Clear the error
    setGenInviteRpcState({});

    const request = GenerateLoginInviteRequest.encode(new GenerateLoginInviteRequest({
      providerId: providerIdParam,
    })).finish();

    SendRpc(getIdTokenClaims, 'generate_login_invite', request)
        .then(r => {
          const response = GenerateLoginInviteResponse.decode(r);
          if (!response.okay || !response.inviteCode) {
            setGenInviteRpcState({state: 'error', message: 'unknown error generating invite'});
            return;
          }

          setGenInviteRpcState({state: 'success'})
          setInviteCode(response.inviteCode);
        })
        .catch(e => {
          setGenInviteRpcState({state: 'error', message: e})
        });
  }

  const getError = (fieldName: string) => {

    // First check in the RPC errors. This can happen if the add
    // operation failed entirely cause there was no name or email.
    for (let i = 0; i < rpcErrors.length; i++) {
      if (rpcErrors[i].path == fieldName) {
        return rpcErrors[i].error;
      }
    }

    // Next check in the bio itself. This is where errors get 
    // stored on the server side.
    if (!bio?.errors?.length) {
      return null;
    }

    for (let i = 0; i < bio.errors.length; i++) {
      if (bio.errors[i].path == fieldName) {
        return bio.errors[i].error;
      }
    }

    return null;
  }

// Clears an error in the local copy of the profile, this is used when editing
// that field to clear the red text.
  const clearError = (fieldName: string) => {
    setBio(bio => {
      return {
        ...bio,
        errors: bio.errors?.filter(e => {
          return e.path != fieldName;
        }) || []
      }
    });
  }

      const submit = () => {

        // Clear the error
        setRpcState({});
        setRpcErrors([]);

        const request = CreateOrUpdateTeamMemberRequest.encode(new CreateOrUpdateTeamMemberRequest({
          isUpdate: isEditing,
          bio: {...bio, errors: []} // no need to pass errors up
        })).finish();

        let method = isEditing ? 'update_team_member' : 'create_team_member';
        SendRpc(getIdTokenClaims, method, request)
            .then(bytes => {
              let response = CreateOrUpdateTeamMemberResponse.decode(bytes)
              if (!response.okay) {
                setRpcState({state: 'error', message: 'Request failed. Check for errors below.'})
              }

              if (response.fatalErrors) {
                setRpcErrors(response.fatalErrors);
              }

              if (response.newProfile) {
                setBusinessProfile(response.newProfile);

                if (isEditing) {
                  // Set the message that it was updated
                  setRpcState({state: 'success'});

                } else {
                  // Leave the page.
                  navigate("/team");
                }
              }
            }).catch(reason => {
              console.log('Server error', reason);
              setRpcState({state: 'error', message: 'Server error, please try again later'})
            }
        )
      }

      return <div className={'ProviderToolPage'}>

        <div className={'SectionHeader'}>
          <div className={'SectionHeaderRow'}>
            <h1>
              {isEditing ? `Edit team member ${bio?.firstName || ''}` : `Add a new team member`}
            </h1>
            <Link to={'/team'}>
              <button className={'BusinessProfileButton StandardButton'}>
                <>Back</>
              </button>
            </Link>
            <button onClick={(e) => {
              e.preventDefault();
              submit()
            }} className={'BusinessProfileButton DarkButton'}>
              Save
            </button>
          </div>

          <div className={'SectionHeaderRow'}>
            <div style={{flex: 1, textAlign: "end"}}>
              {rpcState.state == 'error' && <div className={'InputError'}>{rpcState.message}</div>}
              {rpcState.state == 'success' && <>
                <span className={''}>Profile saved at {
                  saveTime?.toLocaleTimeString(undefined, {
                    hour: 'numeric',
                    minute: '2-digit',
                  })}. </span>
                {bio?.errors?.length != 0 &&
                    <span className={'ErrorText'}>Please check below for errors.</span>
                }
              </>}
            </div>
          </div>
        </div>

        <div className={'ProviderToolAreaScroll'}>
          <div className={'ProviderToolAreaContent'}>

            <br/>
            <br/>

            <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>

              {/*<LabeledTextInput label={'Provider ID'} disabled={isEditing}*/}
              {/*                  value={providerId} onChange={v => setProviderId({value: v})} inputType={'text'}/>*/}

              <div className={'Flex1 HorizontalFlex'}>
                <LabeledTextInput label={'First name'} value={bio.firstName}
                                  error={getError('firstName')}
                                  onChange={v => {
                                    setBio({...bio, firstName: v})
                                    clearError('firstName')
                                  }}
                                  inputType={'text'}/>

                <LabeledTextInput label={'Last name'} value={bio.lastName}
                                  error={getError('lastName')}
                                  onChange={v => {
                                    console.log('v', v)
                                    setBio(bio => {
                                      return {...bio, lastName: v}
                                    })
                                    clearError('lastName')
                                  }}
                                  inputType={'text'}/>

              </div>

              <LabeledTextInput label={'Email'} value={bio.email}
                                error={getError('email')}
                                onChange={v => {
                                  setBio({...bio, email: v})
                                  clearError('email')
                                }}
                                inputType={'text'}
                                helpText={`We'll send an invite link to this email address`}/>

              <LabeledYesNoInput label={'Disable this account'}
                                 helpText={'This person will no longer be accept appointments. You can ' +
                                     're-enable this account later.'}
                                 value={{value: bio.disabled}}
                                 onChange={v => {
                                   setBio({...bio, disabled: v})
                                   clearError('disabled')
                                 }}/>

              <LabeledYesNoInput label={'Is a provider'}
                                 helpText={'Choose yes if this person offers services at your practice, ' +
                                     'no if they are just managing your Kioro profile.'}
                                 value={{value: bio.isProvider || false}}
                                 onChange={v => {
                                   setBio({...bio, isProvider: v})
                                   clearError('isProvider')
                                 }}/>


              {bio.isProvider &&

                  // A whole slew of editable fields are only shown if this person is a provider
                  // an not a business manager.
                  <>


                    <LabeledTextInput label={'Biography'} value={bio.bio}
                                      error={getError('bio')}
                                      onChange={v => {
                                        setBio({...bio, bio: v})
                                        clearError('bio')
                                      }}
                                      inputType={'textarea'}/>

                    <LabeledPhoneInput label={'Phone number'}
                                       value={bio.phone}
                                       error={getError('phone')}
                                       onChange={v => {
                                         setBio({...bio, phone: v})
                                         clearError('phone')
                                       }}/>

                    <LabeledTextInput label={'Job title'} value={bio.jobTitle}
                                      error={getError('jobTitle')}
                                      inputType={'text'}
                                      onChange={v => {
                                        setBio({...bio, jobTitle: v})
                                        clearError('jobTitle')
                                      }}
                    />

                    {/*<LabeledYesNoInput label={'Admin'} helpText={'Does this user have admin privileges?'}*/}
                    {/*                   value={isAdmin} onChange={v => setIsAdmin({value: v})}/>*/}
                    {/*<LabeledYesNoInput label={'Practitioner'} helpText={'Is this person a practitioner?'}*/}
                    {/*                   value={isPractitioner} onChange={v => setIsPractitioner({value: v})}/>*/}

                    <EditableList label={'Education'}
                                  tBuilder={() => new EducationProto()}
                                  renderItem={(props: EditableComponentProps<IEducationProto>) => {
                                    return <EducationInputComponent
                                        value={props.value}
                                        errors={props.errors}
                                        onClearError={props.onClearError}
                                        onChange={props.onChange}
                                        onDelete={props.onDelete}/>
                                  }}
                                  value={bio.education || []}
                                  onChange={v => {
                                    setBio({...bio, education: v})
                                  }}
                                  errors={filterErrors(bio?.errors, 'education.')}
                                  onClearError={pathToClear => {
                                    clearError('education.' + pathToClear);
                                  }}
                    />

                    <EditableList label={'Licenses/Credentials'}
                                  tBuilder={() => new LicenseOrCredentialsProto()}
                                  renderItem={(props: EditableComponentProps<ILicenseOrCredentialsProto>) => {
                                    return <LicenseCredentialComponent
                                        value={props.value}
                                        errors={props.errors}
                                        onChange={props.onChange}
                                        onClearError={props.onClearError}
                                        onDelete={props.onDelete}/>
                                  }}
                                  value={bio.credentials || []}
                                  onChange={v => {
                                    setBio({...bio, credentials: v})
                                  }}
                                  errors={filterErrors(bio?.errors, 'license.')}
                                  onClearError={pathToClear => {
                                    console.log('clearing error', 'license.' + pathToClear)
                                    clearError('license.' + pathToClear);
                                  }}

                    />

                    <PhotoUpload locationName={'Team member'} imageUrl={bio.imageUrls?.length ? bio.imageUrls[0] : ''}
                                 error={findError(bio.errors, 'imageUrl')}
                                 onImageUrlChange={value => {
                                   // hack enforcement of one url
                                   setBio({...bio, imageUrls: [value]})
                                   clearError('imageUrl')
                                 }}/>
                  </>}
            </div>

            <br/>
            <br/>

            {/* the login parts are only shown when editing an existing team member
         this lets you see their login details and resend an invite if necessary
         */}
            {isEditing && <>
              <h2>Login information</h2>

              {loginRpcState.state == 'pending' && <div>Loading...</div>}
              {loginRpcState.state == 'error' && <div className={'ErrorText'}>{loginRpcState.message}</div>}
              {loginRpcState.state == 'success' && login &&
                  <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                    <div>Provider ID: {login.providerId}</div>
                    <div>
                      Login ID: {login.loginId ||
                        <>User has never logged in
                          <div>
                            <button onClick={generateInvite}> click here to generate a new invite?</button>
                            {genInviteRpcState.state == 'error' &&
                                <div className={'ErrorText'}>{genInviteRpcState.message}</div>}
                            {inviteCode &&
                                <div>Here's the new invite code:
                                  <a href={`/providerInvite/${providerIdParam}/${inviteCode}`}>
                                    Copy this link address and send to the person!
                                  </a>
                                </div>}
                          </div>
                        </>}
                    </div>
                    <div>Last login email: {login.lastLoginEmail || 'Unknown'}</div>
                    <div>Last login time: {login.lastLoginTimestamp ?
                        new Date(login.lastLoginTimestamp as number).toLocaleString() : 'Unknown'}</div>
                    <div>Roles: {login?.roles?.join(', ') || 'None'}</div>
                  </div>
              }
              {loginRpcState.state == 'success' && !login &&
                  <div style={{display: 'flex', flexDirection: 'column', gap: 2}}>
                    <div>Couldn't find an associated login. This is an error</div>
                  </div>
              }
            </>
            }

          </div>
        </div>
      </div>
    }
;
