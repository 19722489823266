import React from "react";
import IEducationProto = space.kenko.proto.IEducationProto;
import {space} from "../../../../provider_api";
import {EditableComponentProps} from "../../../../components/input/EditableList";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {findError} from "../../../../components/input/InputTypes";

export const EducationInputComponent = (props: EditableComponentProps<IEducationProto>) => {

  return (<div className={'HorizontalFlex Flex1 Gap20'}>

    <div className={'VerticalFlex Flex1 Gap20'}>

      <LabeledTextInput label={'School'} inputType={'text'}
                        value={props.value.school}
                        error={findError(props.errors, 'school')}
                        onChange={v => {
                          props.onChange({
                            ...props.value,
                            school: v
                          });
                          props.onClearError('school');
                        }}/>

      <div className={'HorizontalFlex Flex1 Gap20'}>

        <LabeledTextInput label={'Degree'} inputType={'text'}
                          value={props.value.degree}
                          error={findError(props.errors, 'degree')}
                          onChange={v => {
                            props.onChange({
                              ...props.value,
                              degree: v
                            });
                            props.onClearError('degree');
                          }}/>

        <LabeledTextInput label={'Year Completed'} inputType={'number'}
                          value={props.value.yearCompleted?.toString()}
                          error={findError(props.errors, 'year')}
                          onChange={v => {
                            props.onChange({
                              ...props.value,
                              yearCompleted: parseInt(v)
                            });
                            props.onClearError('year');
                          }}/>

      </div>
    </div>

    <button onClick={e => {
      e.preventDefault();
      props.onDelete();
    }}>x
    </button>

  </div>);

}
