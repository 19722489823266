import React, {useState} from "react";
import {space} from "../../../../provider_api";
import {SendRpc} from "../../../../rpcSender";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {useAuth0} from "@auth0/auth0-react";
import {RpcState} from "../../../../RpcState";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {FieldWithError, findError} from "../../../../components/input/InputTypes";
import './Calendar.css';
import {LabeledTimeZoneInput} from "../../../../components/input/LabeledTimeZoneInput";
import {RepeatStrategyEditor} from "./RepeatStrategyEditor";
import {CalendarEventType} from "./NewEventModal";
import {LabeledDropdownInput} from "../../../../components/input/LabeledDropdownInput";
import {findProvider} from "./CalendarUtil";
import IEventProto = space.kenko.proto.IEventProto;
import RepeatStrategyProto = space.kenko.proto.RepeatStrategyProto;
import IEventInstanceProto = space.kenko.proto.IEventInstanceProto;
import UpdateInstanceRequest = space.kenko.proto.UpdateInstanceRequest;
import UpdateInstanceResponse = space.kenko.proto.UpdateInstanceResponse;
import IProviderBioProto = space.kenko.proto.IProviderBioProto;
import IErrorProto = space.kenko.proto.IErrorProto;
import EventTypeProto = space.kenko.proto.EventTypeProto;
import IEventTypeProto = space.kenko.proto.IEventTypeProto;

type Props = {
  existingEventId: string,
  existingEvent: IEventInstanceProto
  eventTimeZone: string,

  // The range of the current calendar view, this is used to request all
  // the instances of the newly created event. 
  calendarViewStart: Date,
  calendarViewEnd: Date,

  onUpdated: (event: IEventProto) => void,
  onCancel: () => void,
}

export const EditEventModal = (props: Props) => {

  const {provider, businessProfile} = useProviderProfile();
  const {getIdTokenClaims} = useAuth0();

  // The event that is getting edited
  const [event, setEvent] = useState<IEventInstanceProto>(props.existingEvent);
  const [errorContext, setErrorContext] = useState<IErrorProto[]>([]);

  const [rpcState, setRpcState] = useState<RpcState>();

  const [repeatStrategy, setRepeatStrategy] = useState<RepeatStrategyProto>(
      new RepeatStrategyProto()
  );

  const sendRpc = (isDelete: boolean) => {
    if (isDelete && !window.confirm(`Really delete this instance '${props.existingEvent.details?.title}'?`)) {
      return;
    }

    const request = new UpdateInstanceRequest({
      calendarId: {
        businessId: provider?.businessId,
        calendarId: '100'
      },
      eventId: props.existingEventId,
      startTimestampMs: props.calendarViewStart.getTime(),
      endTimestampMs: props.calendarViewEnd.getTime(),

      instance: event,
    })

    // alert('creating request ' + request)
    console.log(request)

    SendRpc(getIdTokenClaims, "update_event",
        UpdateInstanceRequest.encode(request).finish())
        .then(value => {
          const response = UpdateInstanceResponse.decode(value);
          if (response.updatedEvent) {
            props.onUpdated(response.updatedEvent);
          }
          setErrorContext(response.errors || []);
        })
        .catch(e => {
          alert('error ' + e);
          setRpcState({state: 'error', message: e})
        })
  };

  return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Edit event</h2>


          <LabeledDropdownInput label={'Event Type'}
                                value={event.details?.eventType?.availability ? 'availability' :
                                    event.details?.eventType?.appointment ? 'appointment' : ''}
                                error={findError(errorContext, 'eventType')}
                                options={[
                                  {label: 'Availability', value: 'availability'},
                                  {label: 'Appointment', value: 'appointment'}
                                ]}
                                onChange={v => {
                                  if (v == 'availability') {
                                    setEvent({...event, details: {...event.details, eventType: {
                                      availability: {}
                                    }}});
                                  } else if (v == 'appointment') {
                                    setEvent({...event, details: {...event.details, eventType: {
                                      appointment: {}
                                    }}});
                                  }
                                }}/>

          {event.details?.eventType?.availability &&
              <LabeledDropdownInput label={'Choose a provider'}
                                    value={event.details.eventType.availability.providerId}
                                    error={findError(errorContext, 'provider')}
                                    options={businessProfile?.providers ?
                                        businessProfile.providers?.map(provider => {
                                          return {
                                            value: provider.providerId as string,
                                            label: provider.firstName + ' ' + provider.lastName
                                          }
                                        }) : []}
                                    onChange={(v) => {
                                      setEvent({...event, details: { ...event.details, eventType: {
                                        availability: {
                                          providerId: v
                                        }
                                      }}})}}/>}
          <br/>

          <LabeledTextInput label={'title'} inputType={'text'}
                            value={event.details?.title}
                            onChange={v => setEvent({...event, details: {...event.details, title: v}})}/>

          <LabeledTextInput label={'start time'}
                            value={new Date(event.details?.startTimestamp as number).toLocaleTimeString()}
                            onChange={() => {
                            }}
                            inputType={'text'}
                            disabled={true}/>

          <LabeledTextInput label={'end time'}
                            value={new Date(event.details?.endTimestamp as number).toLocaleTimeString()}
                            onChange={() => {
                            }}
                            inputType={'text'}
                            disabled={true}/>

          <LabeledTimeZoneInput label={'event time zone'}
                                value={props.eventTimeZone}
                                error={''}
                                disabled={true}
                                onChange={() => {
                                }}/>

          <RepeatStrategyEditor
              firstDay={new Date(event.details?.startTimestamp as number)}
              proto={repeatStrategy}
              onChange={(r) => {
                setRepeatStrategy(r)
              }}/>

          <button onClick={props.onCancel}>Cancel</button>
          <button onClick={() => sendRpc(false)}>Update</button>
          <button onClick={() => sendRpc(true)}>Delete</button>
        </div>
      </div>
  );
};
