import React, {ReactElement} from "react";
import {space} from "../../provider_api";
import SurveySessionType = space.kenko.proto.SurveySessionType;
import AppointmentEventType = space.kenko.proto.AppointmentEventType;
import SurveyApptLocations = space.kenko.proto.SurveyApptLocations;
import SurveyRole = space.kenko.proto.SurveyRole;

export type SurveyPage = {
  pageHeaderOverride?: string // originally 'ACCOUNT SETUP'
  title?: string,
  subtitle?: string,
  content: ReactElement,
  validate?: () => boolean;
}

export const sessionTypeToString = (sessionType: SurveySessionType) => {
  switch (sessionType) {
    case SurveySessionType.GROUP_CLASSES:
      return 'Group classes';
    case SurveySessionType.PRIVATE_SESSIONS:
      return 'Private 1:1 sessions';
    case SurveySessionType.SEMI_PRIVATE_SESSIONS:
      return 'Semi-private sessions (2+ clients)'
    default:
      return SurveySessionType[sessionType];
  }
}

export const apptLocationToString = (location: SurveyApptLocations) => {
  switch (location) {
    case space.kenko.proto.SurveyApptLocations.IN_HOME_APPOINTMENTS:
      return 'I travel to my clients (e.g., in-home appointments)'
    case space.kenko.proto.SurveyApptLocations.IN_PERSON_AT_BUSINESS:
      return 'In-person at a business location(s)'
    case space.kenko.proto.SurveyApptLocations.VIRTUAL_APPOINTMENTS:
      return 'Virtual appointments'
    default:
      return SurveyApptLocations[location];
  }
}

export const surveyRoleToString = (role: SurveyRole) => {
  switch (role) {
    case SurveyRole.OFFICE_MANAGER_OR_STAFF:
      return "Office manager or administrative staff";
    case SurveyRole.PRACTITIONER:
      return "Practitioner (e.g., therapist, acupuncturist)";
    case SurveyRole.BUSINESS_OWNER_AND_PRACTITIONER:
      return "Business owner and practitioner";
    case SurveyRole.BUSINESS_OWNER_NON_PRACTITIONER:
      return "Business owner (non-practitioner)";
    case SurveyRole.OTHER:
      return "Other";
    default:
      return SurveyRole[role];
  }
}