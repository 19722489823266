import {Link} from 'react-router-dom';
import React from "react";
import '../App.css'
import {useProviderProfile} from "../../ProviderProfileProvider";

interface Props {
  icon: string,
  link: string,
  text: string,

  // Could be that the profile is awaiting review 
  isBlocked?: boolean,
}

export const LeftNavIcon = (props: Props) => {

  if (props.isBlocked) {
    return <div className={'left-nav-item left-nav-item-blocked'}>
      <img className={'left-nav-icon left-nav-icon-blocked'} src={props.icon}/>
      {props.text}
    </div>
  }

  return <Link to={props.link}>
    <div className={'left-nav-item left-nav-item-available'}>
      <img className={'left-nav-icon left-nav-icon-available'} src={props.icon}/>
      {props.text}
    </div>
  </Link>
};