import React, {createContext, ReactElement, useContext, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "./rpcSender";
import {
  ApiBusinessProto,
  GetProviderProfileRequest,
  GetProviderProfileResponse,
  IApiBusinessProfileProto, IApiBusinessProto,
  IApiProviderLoginProto, space
} from "./provider_api";
import ProfileReviewState = GetProviderProfileResponse.ProfileReviewState;
import BusinessProto = space.kenko.proto.BusinessProto;

// This context contains information about the provider (logged-in-user) and
// the business. It's possible that either is null.
// 
// Setters are provided so users of this context can update the profile,
// typically after doing a mutation via the server.
export const ProviderProfileContext = createContext<{
  provider: IApiProviderLoginProto | null | undefined,
  setProvider: (provider: IApiProviderLoginProto | null | undefined) => void;
  businessProfile: IApiBusinessProfileProto | null | undefined,
  setBusinessProfile: (profile: IApiBusinessProfileProto | null | undefined) => void;
  
  // These 4 are kind of hacks? They're stuffed in here so the client app knows whether
  // or not to show the onboarding checklist, and a banner that there's a pending review.
  business: IApiBusinessProto | null | undefined,
  setBusiness: (business: IApiBusinessProto | null | undefined) => void,
  profileReviewState: ProfileReviewState,
  setProfileReviewState: (profileReviewState: ProfileReviewState) => void,
  profileLoading: boolean,
  profileError: string | null,
}>({
  provider: null,
  setProvider: () => {},
  businessProfile: null,
  setBusinessProfile: () => {},
  business: null,
  setBusiness: () => {},
  profileReviewState: ProfileReviewState.NONE,
  setProfileReviewState: () => {},
  profileLoading: true,
  profileError: null,
});

export const useProviderProfile = () => {
  return useContext(ProviderProfileContext)
}

interface Props {
  children?: ReactElement
}

/**
 * Provides information both about the logged-in user (aka the PROVIDER)
 * and the business. This is loaded from the get_provider_profile action.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const ProviderProfileProvider = (props: Props) => {

  const {user, getIdTokenClaims} = useAuth0();

  const [provider, setProvider] = useState<IApiProviderLoginProto | null | undefined>();
  const [businessProfile, setBusinessProfile] = useState<IApiBusinessProfileProto | null | undefined>();
  const [business, setBusiness] = useState<IApiBusinessProto | null | undefined>();
  const [profileReviewState, setProfileReviewState] = useState(ProfileReviewState.NONE);
  
  const [profileLoading, setProfileLoading] = useState<boolean>(false);
  const [profileError, setProfileError] = useState<string | null>(null);

  useEffect(() => {

    if (!user) {
      setProvider(null);
      setBusinessProfile(null);
      setProfileLoading(false);
      setProfileError(null);
      return;
    }

    // Download it immediately at the beginning...
    console.log('[Fetching provider profile...]');
    setProvider(null);
    setBusinessProfile(null);
    setBusiness(null);
    setProfileReviewState(ProfileReviewState.NONE);
    setProfileLoading(true);
    setProfileError(null);

    SendRpc(getIdTokenClaims, "get_provider_profile",
        GetProviderProfileRequest.encode(new GetProviderProfileRequest()).finish())
        .then(response => {
          let profileResponse = GetProviderProfileResponse.decode(response);
          console.log("Provider profile: " + JSON.stringify(profileResponse.toJSON()))
          setProvider(profileResponse.provider);
          setBusinessProfile(profileResponse.businessProfile);
          setBusiness(profileResponse.business);
          setProfileReviewState(profileResponse.profileReviewState);
        })
        .catch(error => {
          setProfileError(`Error loading profile (${error})`)
        })
        .finally(() => {
          setProfileLoading(false);
        });

  }, [user]);

  return (<ProviderProfileContext.Provider value={{
    provider: provider,
    setProvider: setProvider,
    businessProfile: businessProfile,
    setBusinessProfile: setBusinessProfile,
    business: business,
    setBusiness: setBusiness,
    profileReviewState: profileReviewState,
    setProfileReviewState: setProfileReviewState,
    profileLoading: profileLoading,
    profileError: profileError
  }}>
    {props.children}
  </ProviderProfileContext.Provider>);
};
