import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {LabeledTimeZoneInput} from "../../../../components/input/LabeledTimeZoneInput";
import {RepeatStrategyEditor} from "./RepeatStrategyEditor";
import React, {useState} from "react";

type Props = {
  onCancel: () => void;
  onConfirm: (updateFutureEvents: boolean) => void;
}

/**
 * When the user edits a recurring event this gets shown, asking if they
 * want to edit just this instance or every subsequent instance.
 *
 * Cancel is also an option.
 */
export const ConfirmEditPrompt = (props: Props) => {

  const [futureEvents, setFutureEvents] = useState(false);

  return (<div className="modal-overlay">
    <div className="modal-content">

      <h2>Edit recurring event</h2>
      
      <div>
        <input name='confirmEdit' type={'radio'}
               checked={!futureEvents}
               onChange={e => setFutureEvents(false)}/>
        This event only
      </div>
      
      <div>
        <input name='confirmEdit' type={'radio'}
               checked={futureEvents}
               onChange={e => setFutureEvents(true)}/>
        This and following events
      </div>

      <button onClick={props.onCancel}>Cancel</button>
      <button onClick={() => props.onConfirm(futureEvents)}>Confirm</button>
    </div>
  </div>);

}
