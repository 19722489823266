import React from "react";

type Props<T> = {
  options: {value: T, label:string}[]
  value: T | null,
  onChange: (updated: T) => void,
}


export const RadioSelect = (props: Props<any>) => {

  return props.options.map((choice, i) => {
    
    // Whether this current option is selected
    const optionIsSelected = props.value == choice.value;
    
    return (<button
        key={i}
        className={'survey-checklist ' + (optionIsSelected ? 'selected' : 'notSelected')}
        onClick={() => {
          props.onChange(choice.value);
        }}>
      {choice.label}
    </button>);
  });
}
