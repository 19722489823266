import React, {useEffect, useState} from 'react';
import {CreateServiceRequest, CreateServiceResponse, space} from "../../../../provider_api";
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "../../../../rpcSender";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FieldWithError, findError} from "../../../../components/input/InputTypes";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {RpcState} from "../../../../RpcState";
import {LabeledYesNoInput} from "../../../../components/input/LabeledYesNoInput";
import ServiceProto = space.kenko.proto.ServiceProto;
import IPriceProto = space.kenko.proto.IPriceProto;
import IServiceProto = space.kenko.proto.IServiceProto;
import IErrorProto = space.kenko.proto.IErrorProto;

// This is the page for editing (or adding) a service.
export const AddOrUpdateService = () => {

  // If editing, the sku is a param. 
  const {skuParam} = useParams();
  const isEditing = !!skuParam;

  const {businessProfile, setBusinessProfile} = useProviderProfile();
  const {getIdTokenClaims} = useAuth0();

  const [rpcState, setRpcState] = useState<RpcState>();
  const [saveTime, setSaveTime] = useState<Date>();

  const [workingService, setWorkingService] = useState<IServiceProto>();
  const [errorContext, setErrorContext] = useState<IErrorProto[]>([]);

  const navigate = useNavigate();


  useEffect(() => {

    // Initial population of values if this is in edit mode.
    if (skuParam) {
      if (!businessProfile?.practice?.services) {
        return;
      }

      for (let i = 0; i < businessProfile.practice.services?.length; i++) {
        let service = businessProfile.practice.services[i];
        if (service.sku == skuParam) {
          setWorkingService(service);
          return;
        }
      }
    } else {
      // A blank one.
      setWorkingService(new ServiceProto());
    }
  }, [businessProfile, skuParam])


  const submit = () => {

    // Clear the error
    setRpcState({});

    let request = CreateServiceRequest.encode(new CreateServiceRequest({
      isUpdate: isEditing,
      service: workingService
    })).finish();

    SendRpc(getIdTokenClaims, 'create_service', request)
        .then(bytes => {
          let response = CreateServiceResponse.decode(bytes)

          if (!response.okay) {
            setRpcState({state: 'error', message: 'Server error. Please try again later.'})
            return;
          }

          setRpcState({state: 'success'})
          setSaveTime(new Date())
          setErrorContext(response.errors);
          console.warn('errors', response.errors)

          if (response.newProfile) {

            setBusinessProfile(response.newProfile);

            if (isEditing) {
              // Set the message that it was updated
              setRpcState({state: 'success'});

            } else {
              // Leave the page.
              navigate("/services");
            }
          }
        }).catch(reason => {
          setRpcState({state: 'error', message: 'Server error, please try again later'})
          console.warn(reason);
        }
    )
  }

  const doesPractitionerOfferService = (providerId: string):
      { offered: boolean, price?: IPriceProto | null } => {

    if (!workingService?.offeredBy) {
      return {offered: false};
    }

    for (let i = 0; i < workingService.offeredBy.length; i++) {
      const offeredBy = workingService.offeredBy[i];
      if (offeredBy.providerId == providerId) {
        return {offered: true, price: offeredBy.price};
      }
    }

    return {offered: false};
  }

  //
  const addProviderToOffering = (providerId: string) => {
    console.log('adding provider', providerId)

    setWorkingService(service => ({
      ...service, offeredBy: [...(service?.offeredBy ?? []), {
        providerId: providerId,
        price: {
          currencyCode: 'USD',
          price: 0,
        }
      }]
    }));
  }

  const removeProviderFromOffering = (providerId: string) => {

    setWorkingService(service => ({
      ...service, offeredBy: service?.offeredBy ? [...service.offeredBy.filter(p => {
        return p.providerId != providerId
      })] : []
    }));
  }

  const updateProviderPrice = (providerId: string, price: number) => {

    setWorkingService(service => ({
      ...service, offeredBy: [...(service?.offeredBy ?? []).map(prev => {
        return prev.providerId == providerId ? {
          providerId: providerId,
          price: {
            currencyCode: 'USD',
            price: price
          }
        } : prev;
      })]
    }));
  }

  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>
          {isEditing ? `Edit service "${skuParam}"` : `Add a new Service`}
        </h1>
        <Link to={'/services'}>
          <button className={'BusinessProfileButton StandardButton'}>
            <>Back</>
          </button>
        </Link>
        <button onClick={(e) => {
          e.preventDefault();
          submit()
        }} className={'BusinessProfileButton DarkButton'}>
          Save
        </button>
      </div>


      <div className={'SectionHeaderRow'}>
        <a href={'#details'}>Details</a>
        <a href={'#locations'}>Locations</a>
        <a href={'#team'}>Team Members</a>

        <div style={{flex: 1, textAlign: "end"}}>
          {rpcState?.state == 'error' && <div className={'ErrorText'}>{rpcState.message}</div>}
          {rpcState?.state == 'success' && <div className={''}>Service saved at {
            saveTime?.toLocaleTimeString(undefined, {
              hour: 'numeric',
              minute: '2-digit',
            })}</div>}
        </div>
      </div>
    </div>

    <div className={'ProviderToolAreaScroll'}>
      <div className={'ProviderToolAreaContent'}>


        <h2 id={'details'}>Details</h2>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>

          <LabeledTextInput label={'SKU'} disabled={isEditing}
                            value={workingService?.sku}
                            error={findError(errorContext, 'sku')}
                            onChange={v => {
                              setWorkingService({...workingService, sku: v});
                              setErrorContext(errorContext.filter(e => e.path != 'sku'))
                            }}
                            inputType={'text'}/>

          <LabeledTextInput label={'Name'}
                            value={workingService?.name}
                            error={findError(errorContext, 'name')}
                            onChange={v => {
                              setWorkingService({...workingService, name: v});
                              setErrorContext(errorContext.filter(e => e.path != 'name'))
                            }}
                            inputType={'text'}/>

          <LabeledTextInput label={'Description'}
                            value={workingService?.description}
                            error={findError(errorContext, 'description')}
                            onChange={v => {
                              setWorkingService({...workingService, description: v});
                              setErrorContext(errorContext.filter(e => e.path != 'description'))
                            }}
                            inputType={'textarea'}/>

          <LabeledTextInput label={'Category'}
                            value={workingService?.category}
                            error={findError(errorContext, 'category')}
                            onChange={v => {
                              setWorkingService({...workingService, category: v});
                              setErrorContext(errorContext.filter(e => e.path != 'category'))
                            }}
                            inputType={'text'}/>

          <LabeledTextInput label={'Length (minutes)'}
                            value={workingService?.lengthMinutes?.toString()}
                            error={findError(errorContext, 'lengthMinutes')}
                            onChange={v => {
                              setWorkingService({...workingService, lengthMinutes: parseInt(v)});
                              setErrorContext(errorContext.filter(e => e.path != 'lengthMinutes'))
                            }}
                            inputType={'number'}/>
        </div>

        <br/>
        <br/>

        <h2 id={'locations'}>Locations</h2>
        <div>Select the locations where this service will be offered.</div>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>

          {businessProfile?.locations?.map((location, index) => {

            const availableHere = workingService?.availableLocations &&
                (workingService.availableLocations.indexOf(index) >= 0);

            return <LabeledYesNoInput value={{value: availableHere}}
                                      label={location.locationName || `Unnamed Location ${index}`}
                                      onChange={yes => {
                                        if (yes) {
                                          setWorkingService({
                                            ...workingService,
                                            availableLocations: [...workingService?.availableLocations ?? [], index]
                                          })
                                        } else {
                                          setWorkingService({
                                            ...workingService,
                                            availableLocations:
                                                workingService?.availableLocations?.filter(item => {
                                                  return item != index
                                                })
                                          });
                                        }
                                      }}
                                      helpText={location.address ?? ''}

            />
          })}
        </div>

        <br/>
        <br/>

        <h2 id={'team'}>Team Members</h2>
        <div>Select which team members will offer this service.</div>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>

          {/* Each provider gets a row here. There's a checkbox to say whether they offer this
              service or not. If they do offer the service, there's an additional price field. */}
          {businessProfile?.providers?.map((provider, index) => {

            const offeringInfo = doesPractitionerOfferService(provider.providerId as string);

            return <div className={'HorizontalFlex'} style={{gap: 30}}>
              <LabeledYesNoInput value={{value: offeringInfo.offered}}
                                 label={provider.firstName + ' ' + provider.lastName}
                                 onChange={yes => {
                                   if (yes) {
                                     addProviderToOffering(provider.providerId as string)
                                   } else {
                                     removeProviderFromOffering(provider.providerId as string)
                                   }
                                 }}
                                 helpText={''}/>

              {offeringInfo.offered &&
                  <LabeledTextInput label={'price'}
                                    value={offeringInfo.price?.price?.toString() ?? ''}
                                    onChange={(v) => updateProviderPrice(provider.providerId as string, parseInt(v))}
                                    inputType={'number'}
                                    helpText={''}/>
              }

            </div>
          })}
        </div>

        <br/>
        <br/>
      </div>
    </div>
  </div>
};

