import React from "react";

type Props<T> = {
  options: {value: T, label:string}[]
  value: T[],
  onChange: (updated: T[]) => void,
}


export const MultiSelect = (props: Props<any>) => {

  return props.options.map((choice, i) => {
    
    // Whether this current option is selected
    const optionIsSelected = props.value.includes(choice.value);
    
    return (<button
        key={i}
        className={'survey-checklist ' + (optionIsSelected ? 'selected' : 'notSelected')}
        onClick={() => {

          if (optionIsSelected) {
            // Deselecting (remove each instance from list)
            props.onChange(props.value.filter(value => {
              return value != choice.value;
            }));
          } else {
            // Selecting (append to list)
            props.onChange([...props.value, choice.value]);
          }
        }}>
      {choice.label}
    </button>);
  });
}
