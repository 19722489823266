import React, {useMemo, useState} from "react";
import {space} from "../../../../provider_api";
import {SendRpc} from "../../../../rpcSender";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {useAuth0} from "@auth0/auth0-react";
import {RpcState} from "../../../../RpcState";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {findError} from "../../../../components/input/InputTypes";
import './Calendar.css';
import {LabeledTimeZoneInput} from "../../../../components/input/LabeledTimeZoneInput";
import {RepeatStrategyEditor} from "./RepeatStrategyEditor";
import {LabeledDropdownInput} from "../../../../components/input/LabeledDropdownInput";
import {findProvider} from "./CalendarUtil";
import {LabeledPhoneInput} from "../../../../components/input/LabeledPhoneInput";
import CreateEventRequest = space.kenko.proto.CreateEventRequest;
import CreateEventResponse = space.kenko.proto.CreateEventResponse;
import IEventProto = space.kenko.proto.IEventProto;
import RepeatStrategyProto = space.kenko.proto.RepeatStrategyProto;
import ICreateEventRequest = space.kenko.proto.ICreateEventRequest;
import IErrorProto = space.kenko.proto.IErrorProto;
import IEventTypeProto = space.kenko.proto.IEventTypeProto;
import EventTypeProto = space.kenko.proto.EventTypeProto;

type Props = {
  // these are from the slot that was chosen on the calendar, can be
  // changed by this UI.
  defaultStartTime: Date,
  defaultEndTime: Date,

  // The range of the current calendar view, this is used to request all
  // the instances of the newly created event. 
  calendarViewStart: Date,
  calendarViewEnd: Date,

  onCreate: (event: IEventProto) => void,
  onCancel: () => void,
}

export type CalendarEventType = 'availability' | 'appointment';

export const NewEventModal = (props: Props) => {

  const profile = useProviderProfile();
  const {getIdTokenClaims} = useAuth0();

  const [request, setRequest] = useState<ICreateEventRequest>(new CreateEventRequest({
    calendarId: {
      businessId: profile.provider?.businessId,
      calendarId: '100'
    },
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    repeatStrategy: new RepeatStrategyProto({
      none: {},
    }),
    details: {
      startTimestamp: props.defaultStartTime.getTime(),
      endTimestamp: props.defaultEndTime.getTime(),
    },

    // These are not directly relevant to event creation but are sent up so the server
    // can send down the most recent events in the response.
    clientStartTimestampMs: props.calendarViewStart.getTime(),
    clientEndTimestampMs: props.calendarViewEnd.getTime(),
  }));

  const [rpcState, setRpcState] = useState<RpcState>();

  // Errors that came down in the rpc response with paths like "startTimestamp", "provider", etc.
  const [errors, setErrors] = useState<IErrorProto[]>([]);

  // This is a shorthand that calls setRequest, but used when editing just the event type.
  // The specific event type editors (availability, appointment) use this extensively.
  const setRequestEventType = (lambda: (event?: IEventTypeProto | null) => IEventTypeProto): void => {
    setRequest(request => {
      return {
        ...request,
        details: {
          ...request.details,
          eventType: lambda(request.details?.eventType)
        }
      }
    })
  }

  const sendRpc = () => {
    console.log(request)

    SendRpc(getIdTokenClaims, "create_event",
        CreateEventRequest.encode(request).finish())
        .then(value => {
          const response = CreateEventResponse.decode(value);
          setErrors(response.errors);
          if (response.created) {
            props.onCreate(response.created);
          }
        })
        .catch(e => {
          alert('error ' + e);
          setRpcState({state: 'error', message: e})
        })
  };

  const eventTypeAsString = (eventType: IEventTypeProto | null | undefined): CalendarEventType | undefined => {
    if (!eventType) {
      return undefined;
    }

    // Weird --- the class contains the string oneof but not the interface.
    return (eventType as EventTypeProto).EventType;
  }

  const getDefaultEventType = (typeString: CalendarEventType | undefined | null): EventTypeProto | undefined => {
    if (typeString == 'availability') {
      return new EventTypeProto({
        availability: {}
      })
    }

    if (typeString == 'appointment') {
      return new EventTypeProto({
        appointment: {}
      })
    }

    return undefined;
  }


  /**
   * Each calendar event type has its own editor. This could be important down the road
   * since event types could get quite different! Think of private appointments vs public
   * classes (w/ lots of students) or even some kind of appointment with 2 teachers.
   *
   * Note that it's safe to assume that in these editors, the correct 'oneof' is already
   * selected in the request proto.
   */
  const AvailabilityBlockEditor = () => {

    return <>
      <LabeledDropdownInput label={'Choose a provider'}
                            value={request.details?.eventType?.availability?.providerId}
                            error={findError(errors, 'provider')}
                            options={profile.businessProfile?.providers ?
                                profile.businessProfile.providers?.map(provider => {
                                  return {
                                    value: provider.providerId as string,
                                    label: provider.firstName + ' ' + provider.lastName
                                  }
                                }) : []}
                            onChange={v => setRequestEventType(previous => {
                              return {
                                ...previous,
                                availability: {
                                  providerId: findProvider(profile.businessProfile?.providers, v)?.providerId
                                }
                              }
                            })}/>
    </>
  }

  /**
   * Each calendar event type has its own editor. This could be important down the road
   * since event types could get quite different! Think of private appointments vs public
   * classes (w/ lots of students) or even some kind of appointment with 2 teachers.
   *
   * Note that it's safe to assume that in these editors, the correct 'oneof' is already
   * selected in the request proto.
   * 
   * This is useMemo'd because not doing so cause the thing to rerender, and apparently
   * it created different DOM widgets because all of the inputs lost focus immediately
   * after typing a letter.
   */
  const AppointmentBlockEditor = useMemo(() => <>
    <LabeledDropdownInput label={'Choose a provider'}
                          value={request.details?.eventType?.appointment?.providerId}
                          error={findError(errors, 'provider')}
                          options={profile.businessProfile?.providers ?
                              profile.businessProfile.providers?.map(provider => {
                                return {
                                  value: provider.providerId as string,
                                  label: provider.firstName + ' ' + provider.lastName
                                }
                              }) : []}
                          onChange={v => {
                            console.log('founded founded', v)
                            setRequestEventType(previous => {
                              return {
                                ...previous,
                                appointment: {
                                  ...previous?.appointment,
                                  providerId: findProvider(profile.businessProfile?.providers, v)?.providerId
                                }
                              }
                            })
                          }}/>

    <LabeledTextInput label={'Client name'}
                      value={request.details?.eventType?.appointment?.clientName}
                      error={findError(errors, 'clientName')}
                      inputType={'text'}
                      onChange={v => setRequestEventType(previous => {
                        return {
                          ...previous,
                          appointment: {
                            ...previous?.appointment,
                            clientName: v
                          }
                        }
                      })}/>

    <LabeledPhoneInput label={'Client phone number'}
                       value={request.details?.eventType?.appointment?.clientPhone}
                       error={findError(errors, 'clientPhone')}
                       onChange={v => setRequestEventType(previous => {
                         return {
                           ...previous,
                           appointment: {
                             ...previous?.appointment,
                             clientPhone: v
                           }
                         }
                       })}/>

    <LabeledTextInput label={'Client email'}
                      value={request.details?.eventType?.appointment?.clientEmail}
                      error={findError(errors, 'clientEmail')}
                      inputType={'email'}
                      onChange={v => setRequestEventType(previous => {
                        return {
                          ...previous,
                          appointment: {
                            ...previous?.appointment,
                            clientEmail: v
                          }
                        }
                      })}/>
  </>, [request, errors]);

  return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>New event</h2>

          <LabeledDropdownInput label={'Event Type'}
                                value={eventTypeAsString(request.details?.eventType)}
                                error={findError(errors, 'eventType')}
                                options={[
                                  {label: 'Availability', value: 'availability'},
                                  {label: 'Appointment', value: 'appointment'}
                                ]}
                                onChange={v => setRequest(request => {
                                  return {
                                    ...request,
                                    details: {
                                      ...request.details,
                                      eventType: getDefaultEventType(v as CalendarEventType)
                                    }
                                  }
                                })}/>

          {request.details?.eventType?.availability && <AvailabilityBlockEditor/>}
          {request.details?.eventType?.appointment && AppointmentBlockEditor}

          <LabeledTextInput label={'Title'} inputType={'text'}
                            value={request.details?.title}
                            error={findError(errors, 'title')}
                            onChange={v => setRequest(request => {
                              return {
                                ...request,
                                details: {
                                  ...request.details,
                                  title: v
                                }
                              }
                            })}/>

          <LabeledTextInput label={'Start time'}
                            value={new Date(request.details?.startTimestamp as number).toLocaleTimeString(undefined, {
                              hour: "numeric", minute: 'numeric'
                            })}
                            error={findError(errors, 'startTimestamp')}
                            inputType={'text'}
                            disabled={true}
                            onChange={() => {
                            }}/>

          <LabeledTextInput label={'End time'}
                            value={new Date(request.details?.endTimestamp as number).toLocaleTimeString(undefined, {
                              hour: "numeric", minute: 'numeric'
                            })}
                            error={findError(errors, 'endTimestamp')}
                            inputType={'text'}
                            disabled={true}
                            onChange={() => {
                            }}/>

          <LabeledTimeZoneInput label={'Time zone'}
                                value={request.timeZone}
                                error={findError(errors, 'timeZone')}
                                onChange={v => setRequest(request => {
                                  return {
                                    ...request,
                                    timeZone: v
                                  }
                                })}/>

          <RepeatStrategyEditor firstDay={new Date(request.details?.startTimestamp as number)}
                                proto={request.repeatStrategy ?? new RepeatStrategyProto()}
                                onChange={v => setRequest(request => {
                                  return {
                                    ...request,
                                    repeatStrategy: v
                                  }
                                })}/>

          <button onClick={props.onCancel}>Cancel</button>
          <button onClick={sendRpc}>Create</button>
        </div>
      </div>
  );
};

