import {LabeledTextInput} from "../../../components/input/LabeledTextInput";
import React, {useState} from "react";
import {LabeledCountryInput} from "../../../components/input/LabeledCountryInput";
import {AcceptTosInput} from "./AcceptTosInput";
import {SendRpc} from "../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import {
  CreateOrUpdateTeamMemberRequest,
  CreateOrUpdateTeamMemberResponse,
  UpdateProviderProfileRequest, UpdateProviderProfileResponse
} from "../../../provider_api";
import {useProviderProfile} from "../../../ProviderProfileProvider";
import {LabeledPhoneInput} from "../../../components/input/LabeledPhoneInput";
import {FieldWithError} from "../../../components/input/InputTypes";

export const CreateProviderProfile = () => {

  const {user, getIdTokenClaims} = useAuth0();
  const {provider, setProvider, businessProfile, setBusinessProfile} = useProviderProfile();


  const [firstName, setFirstName] = useState<FieldWithError<string>>({value: ''});
  const [lastName, setLastName] = useState<FieldWithError<string>>({value: ''});
  const [contactEmail, setContactEmail] = useState<FieldWithError<string>>({value: user?.email});
  const [mobileNumber, setMobileNumber] = useState<FieldWithError<string>>({value: ''});
  const [country, setCountry] = useState<FieldWithError<string>>({value: 'US'});
  const [acceptedTos, setAcceptedTos] = useState<FieldWithError<boolean>>({value: false});
  const [sendingRpc, setSendingRpc] = useState<FieldWithError<boolean>>({value: false})


  const submit = () => {

    let error = false;

    if (!firstName.value) {
      setFirstName({...firstName, error: "Please enter your first name"});
      error = true;
    }

    if (!lastName.value) {
      setLastName({...lastName, error: "Please enter your last name"});
      error = true;
    }

    if (!contactEmail.value) {
      setContactEmail({...contactEmail, error: "Please enter a contact email"});
      error = true;
    }

    if (!mobileNumber.value) {
      setMobileNumber({...mobileNumber, error: "Please enter your mobile number"});
      error = true;
    }

    if (country.value != 'US') {
      setCountry({...country, error: "Sorry, this country is not supported yet!"});
      error = true;
    }

    if (!acceptedTos.value) {
      setAcceptedTos({...acceptedTos, error: "You must accept these to use Kenko"});
      error = true;
    }

    if (!error) {

      setSendingRpc({value: true, error: null});

      let request = UpdateProviderProfileRequest.encode(
          new UpdateProviderProfileRequest({
                profile: {
                  firstName: firstName.value,
                  email: contactEmail.value,
                  lastName: lastName.value,
                  mobileNumber: mobileNumber.value,
                  countryCode: 'US',
                  agreedToTerms: acceptedTos.value,
                }
              }
          )).finish();

      SendRpc(getIdTokenClaims, 'update_provider_profile', request)
          .then(resp => {
            let response = UpdateProviderProfileResponse.decode(resp);
            if (!response.okay) {
              setSendingRpc({value: false, error: 'Error updating profile. Please try again later.'})
              return;
            }

            setProvider(response.provider);

          }).catch(e => {
        setSendingRpc({value: false, error: `Error updating profile. ${e}`});
      });
    }
  };

  return <div className={'CreateProfileSection'}>

    <h1>Create your profile</h1>

    <LabeledTextInput label={'First name'} value={firstName.value} error={firstName.error} inputType={'text'}
                      onChange={v => setFirstName({value: v, error: null})}/>
    <LabeledTextInput label={'Last name'} value={lastName.value} error={lastName.error} inputType={'text'}
                      onChange={v => setLastName({value: v, error: null})}/>
    <LabeledTextInput label={'Contact email'} value={contactEmail.value} error={contactEmail.error} inputType={'email'}
                      onChange={v => setContactEmail({value: v, error: null})}/>
    <LabeledPhoneInput label={'Mobile number'} value={mobileNumber.value} error={mobileNumber.error}
                       onChange={v => setMobileNumber({value: v, error: null})}/>

    <LabeledCountryInput label={'Country'} value={country} onChange={v =>
        setCountry({value: v, error: v == 'US' ? null : "Sorry, this country is not supported yet!"})}/>

    <AcceptTosInput value={acceptedTos} onChange={v => setAcceptedTos({value: v, error: null})}/>

    <button onClick={submit} disabled={sendingRpc.value || false} className={'CreateProfileDarkButton'}>
      {sendingRpc.value &&
          <img className={"CreateProfileSpinner"} src={require('../../../images/loading_spinner.gif')}/>}
      {!sendingRpc.value && <>Join Kenko</>}
    </button>

    {sendingRpc.error && <div className={'LabeledInputError'}>{sendingRpc.error}</div>}

    <div style={{height: 100}}/>

  </div>


}