import React, {useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {filterErrors, findError} from "../../../../components/input/InputTypes";
import {
  ApiBusinessProfileProto,
  IApiBusinessProfileProto,
  space,
  UpdateBusinessProfileRequest,
  UpdateBusinessProfileResponse
} from "../../../../provider_api";
import {SendRpc} from "../../../../rpcSender";
import {LabeledTextInput} from "../../../../components/input/LabeledTextInput";
import {LabeledPhoneInput} from "../../../../components/input/LabeledPhoneInput";
import {BusinessLocation} from "./BusinessLocation";
import '../../../App.css';
import {PhotoUpload} from "../../../../components/input/PhotoUpload";
import BusinessLocationProto = space.kenko.proto.BusinessLocationProto;
import IErrorProto = space.kenko.proto.IErrorProto;
import IBusinessLocationProto = space.kenko.proto.IBusinessLocationProto;

type RpcState = 'pending' | 'success' | 'error' | 'none';

export const BusinessProfile = () => {

  const {businessProfile, setBusinessProfile} = useProviderProfile();
  const {getIdTokenClaims} = useAuth0()

  // This is the working copy of the profile. 
  const [workingProfile, setWorkingProfile] = useState<IApiBusinessProfileProto>(
      new ApiBusinessProfileProto(businessProfile || {}))

  const [rpcState, setRpcState] = useState<RpcState>('none')
  const [saveTime, setSaveTime] = useState<Date>();

  // This updates the working profile whenever it changes on the server
  useEffect(() => {
    if (businessProfile) {
      setWorkingProfile(businessProfile);
    }
  }, [businessProfile])

  if (!businessProfile || !businessProfile.proto) {
    return <div>error - no profile!</div>
  }

  const addLocation = () => {

    // Two spreads necessary here to make sure there are no in-place mutations!
    setWorkingProfile(profile => {
      return {
        ...profile, locations: [...profile.locations || [], new BusinessLocationProto({
          address: '',
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          lat: 0,
          lng: 0,
          locationName: '',
          email: '',
          phone: '',
          imageUrl: []
        })]
      }
    });
  };

  // Updates a specific location by index. I need to do lambda in order to allow multiple
  // calls per frame (e.g. RPC callback, when there are many errors). Otherwise only
  // the last one takes.
  const updateLocation = (i: number, newLocation: IBusinessLocationProto) => {

    setWorkingProfile(profile => {
      return {
        ...profile,
        locations: profile.locations?.map((existingLocation, index) => {
          return index == i ? newLocation : existingLocation;
        })
      };
    })
  };

  const submit = () => {

    setRpcState('pending');

    let request = UpdateBusinessProfileRequest.encode(new UpdateBusinessProfileRequest({
      businessProfile: workingProfile
    })).finish();

    SendRpc(getIdTokenClaims, 'update_business_profile', request)
        .then(resp => {
          let response = UpdateBusinessProfileResponse.decode(resp);
          if (!response.okay) {
            setRpcState('error');
            return;
          }

          console.log('New profile', response)

          // Note any errors are embedded in this!
          setBusinessProfile(response.profile);
          setRpcState('success');
          setSaveTime(new Date())

        }).catch(e => {
          setRpcState('error');
          console.warn(e);
        }
    );
  };

  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Business Profile</h1>
        <button className={'BusinessProfileButton'}>Cancel</button>
        <button onClick={submit} disabled={rpcState == 'pending'} className={'BusinessProfileButton DarkButton'}>
          {rpcState == 'pending' &&
              <img className={"BusinessProfileSaveSpiner"} src={require('../../../../images/loading_spinner.gif')}/>}
          {rpcState != 'pending' && <>Save</>}
        </button>
      </div>

      <div className={'SectionHeaderRow'}>
        <a href={'#general'}>General</a>
        <a href={'#locations'}>Locations</a>
        <a href={'#photos'}>Photos</a>

        <div style={{flex: 1, textAlign: "end"}}>
          {rpcState == 'error' &&
              <div className={'ErrorText'}>Server error. Please try again later</div>}
          {rpcState == 'success' &&
              <>
                <span className={''}>Profile saved at {
                  saveTime?.toLocaleTimeString(undefined, {
                    hour: 'numeric',
                    minute: '2-digit',
                  })}. </span>
                {businessProfile?.errors?.length != 0 &&
                    <span className={'ErrorText'}>Please check below for errors.</span>
                }
              </>}
        </div>
      </div>
    </div>

    <div className={'ProviderToolAreaScroll'}>
      <div className={'ProviderToolAreaContent'}>

        <h2 id={'general'}>General</h2>
        <div className={'InputSection'}>
          <LabeledTextInput label={'Business name'} value={workingProfile.proto?.businessName}
                            error={findError(workingProfile.errors, 'businessName')}
                            onChange={v => {
                              setWorkingProfile({
                                ...workingProfile,
                                proto: {...workingProfile.proto, businessName: v},
                                errors: workingProfile.errors?.filter(e => {
                                  return e.path != 'businessName';
                                })
                              });
                            }}
                            inputType={'text'}/>

          <LabeledTextInput label={'About your business'} value={workingProfile.proto?.businessDescription}
                            error={findError(workingProfile.errors, 'businessDescription')}
                            onChange={v => {
                              setWorkingProfile({
                                ...workingProfile,
                                proto: {...workingProfile.proto, businessDescription: v},
                                errors: workingProfile.errors?.filter(e => {
                                  return e.path != 'businessDescription';
                                })
                              });
                            }}
                            inputType={'textarea'}/>

          <LabeledPhoneInput label={'Primary business phone number'} value={workingProfile.proto?.phoneNumber}
                             error={findError(workingProfile.errors, 'phoneNumber')}
                             onChange={v => {
                               setWorkingProfile({
                                 ...workingProfile,
                                 proto: {...workingProfile.proto, phoneNumber: v},
                                 errors: workingProfile.errors?.filter(e => {
                                   return e.path != 'phoneNumber';
                                 })
                               });
                             }}/>


          <LabeledTextInput label={'Primary business email'} value={workingProfile.proto?.email}
                            error={findError(workingProfile.errors, 'email')}
                            inputType={'text'}
                            onChange={v => {
                              setWorkingProfile({
                                ...workingProfile,
                                proto: {...workingProfile.proto, email: v},
                                errors: workingProfile.errors?.filter(e => {
                                  return e.path != 'email';
                                })
                              });
                            }}/>

          <LabeledTextInput label={'Short URL'} value={workingProfile.proto?.shortUrl}
                            error={findError(workingProfile.errors, 'shortUrl')}
                            inputType={'text'}
                            onChange={v => {
                              setWorkingProfile({
                                ...workingProfile,
                                proto: {...workingProfile.proto, shortUrl: v.trim()},
                                errors: workingProfile.errors?.filter(e => {
                                  return e.path != 'shortUrl';
                                })
                              });
                            }}/>

          <LabeledTextInput label={'Business website'} value={workingProfile.proto?.websiteUrl}
                            error={findError(workingProfile.errors, 'websiteUrl')}
                            inputType={'text'}
                            onChange={v => {
                              setWorkingProfile({
                                ...workingProfile,
                                proto: {...workingProfile.proto, websiteUrl: v},
                                errors: workingProfile.errors?.filter(e => {
                                  return e.path != 'websiteUrl';
                                })
                              });
                            }}/>
        </div>

        <div className={'Flex1 HorizontalFlex'}>
          <div className={'Flex1'}><a id={'locations'}><h2>Business Locations</h2></a></div>
          <button className={'AddLocationButton'} onClick={addLocation}>Add a location</button>
        </div>

        <div className={'BusinessLocationSection'}>
          {workingProfile.locations?.map((location, i) => {
            // Any change to a field clears all the values.
            return <BusinessLocation value={location}
                                     errorContext={filterErrors(businessProfile?.errors, `location.${i}.`)}
                                     onChange={value => {
                                       updateLocation(i, value)
                                     }}/>
          })}
        </div>

        <h2 id={'photos'}>Photos</h2>
        <div className={'BusinessLocationSection'}>

          <PhotoUpload locationName={'General'}
                       error={findError(workingProfile.errors, 'imageUrl')}
                       imageUrl={workingProfile.proto?.imageUrl?.length ? workingProfile.proto.imageUrl[0] : ''}
                       onImageUrlChange={value => setWorkingProfile(profile => {
                         return {...profile, proto: {...profile.proto, imageUrl: [value]}}
                       })}/>

          {workingProfile.locations?.map((location, i) => {
            return <PhotoUpload
                locationName={location.locationName || 'Location ' + i}
                error={findError(workingProfile?.errors, `location.${i}.imageUrl`)}
                imageUrl={location.imageUrl?.length ? location.imageUrl[0] : ''}
                onImageUrlChange={value => updateLocation(i, {...location, imageUrl: [value]})}
            />
          })}
        </div>
      </div>
    </div>
  </div>;

}