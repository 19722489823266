import React, {useEffect, useState} from 'react';
import {
  GetLoginsForBusinessRequest,
  GetLoginsForBusinessResponse,
  IApiProviderLoginProto
} from "../../../../provider_api";
import {useProviderProfile} from "../../../../ProviderProfileProvider";
import {Link} from "react-router-dom";
import {SendRpc} from "../../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";

export const Team = () => {

  const {provider, businessProfile} = useProviderProfile();

  const {getIdTokenClaims, user} = useAuth0();

  const isAdmin = () => {
    return provider?.roles && provider.roles.indexOf('admin') >= 0;
  }

  return <div className={'ProviderToolPage'}>

    <div className={'SectionHeader'}>
      <div className={'SectionHeaderRow'}>
        <h1>Team Members</h1>
        <Link to={'add'} state={{newService: true}}>
          <button className={'BusinessProfileButton DarkButton'}>
            Add
          </button>
        </Link>
      </div>
    </div>

    <div className={'ProviderToolAreaScroll'}>
      <div className={'ProviderToolAreaContent'}>

        <table>
          <thead>
          <tr>
            <th>Photo</th>
            <th>Name</th>
            <th>Contact email</th>
            <th>Phone number</th>
            <th>Is Practitioner</th>
            <th>Actions</th>
            <th>Status</th>
          </tr>
          </thead>

          <tbody>
          {businessProfile?.providers?.map(bio => {
            return <tr key={bio.providerId}>
              <td><img style={{maxWidth: 50, maxHeight: 50}}
                       src={bio.imageUrls?.length ? bio.imageUrls[0] : undefined}/></td>
              <td>{bio.firstName} {bio.lastName}</td>
              <td>{bio.email}</td>
              <td>{bio.phone}</td>
              <td>{bio.isProvider ? 'yes' : ''}</td>
              <td>
                {(isAdmin() || (provider?.providerId == bio.providerId)) &&
                    <Link to={`/team/edit/${bio.providerId}`}>Edit</Link>}
              </td>
              <td>{(bio.errors?.length == 0) ? (bio.disabled ? 'Disabled' : "Ok") :
                  <span className={'ErrorText'}>Please fix errors</span>}
              </td>
            </tr>
          })}
          </tbody>

        </table>


        {!businessProfile?.providers?.length && <div>
          <br/><br/>
          Click "Add" above to add your first team member!
        </div>}

      </div>
    </div>
  </div>

};

