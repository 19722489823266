import React, {useState} from 'react';
import axios, {AxiosRequestConfig} from 'axios';
import {useAuth0} from "@auth0/auth0-react";

type Props = {
  locationName: string,
  imageUrl: string | null | undefined,
  error?: string | null | undefined,
  onImageUrlChange: (url: string) => void,
}

export const PhotoUpload = (props: Props) => {

  const [error, setError] = useState<string>();
  const {getIdTokenClaims} = useAuth0()

  const [uploadProgress, setUploadProgress] = useState(0);

  const host = window.location.host

  /**
   * Selecting a file automatically uploads it to cloud storage and then renders the
   * image from that hosting URL.
   */
  function handleChange(file: File | undefined) {
    console.log(file)

    if (!file) {
      console.log('no file selected')
      return;
    }

    if (file.size > (5 * 1024 * 1024)) {
      setError('Image exceeds max size of 5 MB')
      return;
    }

    setError(undefined);

    getIdTokenClaims()
        .then(token => {

          let url = 'http://localhost:8082/upload';
          if (host.endsWith("provider.thymebook.com")) {
            url = 'https://provider-1096702705153.us-central1.run.app/upload'
          }

          const formData = new FormData();
          formData.append('file', file);
          const config: AxiosRequestConfig = {
            headers: {
              'Authentication': token?.__raw ?? '',
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              setUploadProgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? file.size)));
            },
          };
          axios.post(url, formData, config)
              .then((response) => {
                console.log(response.data);
                props.onImageUrlChange(response.data);
              })
              .catch((error) => {
                console.error(error.toString());
                setError(error.toString());
              })
              .finally(() => {
                setUploadProgress(0);
              });
        })
        .catch(e => {
          setError("Auth error. " + e.toString());
        });
  }

  return <div className={'VerticalFlex Flex1'}>
    <div className={'LabeledTextInputLabel'}>{props.locationName} photo(s)</div>
    {props.imageUrl && <img src={props.imageUrl} style={{width: 300}} alt="Uploaded content"/>}
    <input type="file" className={'LabeledTextInput'} onChange={(e) => {
      if (e.target.files && e.target.files.length > 0) {
        handleChange(e.target.files[0]);
      } else {
        handleChange(undefined);
      }
    }}/>
    {uploadProgress > 0 && <div>
      <progress value={uploadProgress} max="100"/>
      {uploadProgress}%</div>}
    {error && <div style={{color: 'red'}}>{error}</div>}
    {props.error && <div className={'LabeledInputError'}>{props.error}</div>}
  </div>
};

