import React, {useContext} from 'react';
import './Provider.css';
import NewProviderSurvey from "./Survey/NewProviderSurvey";
import {ProviderProfileContext} from "../../ProviderProfileProvider";
import {Routes} from "react-router";
import {Route} from "react-router-dom";
import {DeleteBusinessDebug} from "./Sections/DeleteBusinessDebug";
import {Services} from "./Sections/Services/Services";
import {LeftNavIcon} from "./LeftNavIcon";
import {CreateProviderProfile} from "./Survey/CreateProviderProfile";
import {BusinessProfile} from "./Sections/BusinessProfile/BusinessProfile";
import {AddOrUpdateService} from "./Sections/Services/AddOrUpdateService";
import {Team} from "./Sections/Team/Team";
import {AddOrUpdateTeam} from "./Sections/Team/AddOrUpdateTeam";
import {Website} from "./Sections/Website/Website";
import {ProviderCalendar} from "./Sections/Calendar/ProviderCalendar";
import {Home} from "./Sections/Home/Home";
import {space} from "../../provider_api";
import ProviderApprovalState = space.kenko.proto.ProviderApprovalState;

/**
 * The user is guaranteed to be logged in at this point because the
 * authentication happens in App.tsx.
 */
export const ProviderHome = () => {

  const {provider, business} = useContext(ProviderProfileContext);

  // After an invite is redeemed, there shoould always be a provider and business 
  // (although the profiles are mostly empty).
  if (!provider || !business) {
    return <div>Error, no profile</div>;
  }

  // If this is empty the provider needs to fill out their profile. This is the
  // first step after redeeming the invite.
  if (!provider.profile) {
    return <CreateProviderProfile/>
  }

  if (business?.approvalState == ProviderApprovalState.APPROVAL_STATE_UNKNOWN) {
    return <div>Error, unknown approval state for this business</div>;
  }
  
  // Second step in the onboarding is the provider survey. This first draft
  // version is only created after the survey is taken.
  // Survey has to be taken.
  if (business?.approvalState == ProviderApprovalState.NEW_PROVIDER_AUTO_APPROVE ||
      business?.approvalState == ProviderApprovalState.NEW_PROVIDER_MANUAL_APPROVE) {
    return <NewProviderSurvey/>;
  }
  
  const isAdmin = provider.roles && provider.roles.indexOf('admin') >= 0;
  
  const isAwaitingReview = business.approvalState == ProviderApprovalState.AWAITING_REVIEW ||
      business.approvalState == ProviderApprovalState.PROVIDER_REJECTED;

  return (<div className={'ProviderMainArea'}>

        {/* The left nav bar containing all the tools */}
        <div className={'LeftNav'}>
          <LeftNavIcon text='Home' icon={require('../../images/icons/home.png')} link={'/'}/>
          <LeftNavIcon isBlocked={isAwaitingReview} text='Calendar' icon={require('../../images/icons/calendar_today.png')} link={'/calendar'}/>

          {isAdmin && <LeftNavIcon isBlocked={isAwaitingReview}
                                   text='Services' icon={require('../../images/icons/format_list_bulleted.png')}
                                   link={'/services'}/>}
          
          <LeftNavIcon isBlocked={isAwaitingReview}
                       text='Team' icon={require('../../images/icons/groups.png')} link={'/team'}/>
         
          {isAdmin && <LeftNavIcon isBlocked={isAwaitingReview}
                                   text='Business Profile'
                                   icon={require('../../images/icons/contact_page.png')}
                                   link={'/profile'}/>}
          
          {isAdmin && <LeftNavIcon isBlocked={isAwaitingReview}
                                   text='Website' icon={require('../../images/icons/web.png')} link={'/website'}/>}

          {isAdmin && <LeftNavIcon text='Debug Tools' icon={require('../../images/icons/build.png')}
                                   link={'/delete-business'}/>}
          <br/>
        </div>


        {/* The main body of the tool- And the right which takes up the most of the page*/}
        <div className='ProviderToolArea'>

          <Routes>
            <Route path={'/*'} element={<Home/>}/>
            {isAdmin && <Route path={'/profile'} element={<BusinessProfile/>}/>}
            <Route path={'/calendar'} element={<ProviderCalendar/>}/>
            {isAdmin && <Route path={'/services/add'} element={<AddOrUpdateService/>}/>}
            {isAdmin && <Route path={'/services/edit/:skuParam'} element={<AddOrUpdateService/>}/>}
            {isAdmin && <Route path={'/services'} element={<Services/>}/>}
            {isAdmin && <Route path={'/team/add'} element={<AddOrUpdateTeam/>}/>}
            <Route path={'/team/edit/:providerIdParam'} element={<AddOrUpdateTeam/>}/>
            <Route path={'/team'} element={<Team/>}/>
            {isAdmin && <Route path={'/website'} element={<Website/>}/>}
            {isAdmin && <Route path={'/delete-business'} element={<DeleteBusinessDebug/>}/>}
          </Routes>

        </div>
      </div>
  );
}
