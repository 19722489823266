import {space} from "../../../../provider_api";
import React, {useState} from "react";
import {LabeledDropdownInput} from "../../../../components/input/LabeledDropdownInput";
import RepeatStrategyProto = space.kenko.proto.RepeatStrategyProto;
import DayOfWeekProto = space.kenko.proto.DayOfWeekProto;
import WeeklyRepeatSchedule = space.kenko.proto.WeeklyRepeatSchedule;
import IWeeklyRepeatSchedule = space.kenko.proto.IWeeklyRepeatSchedule;
import {Day} from "react-big-calendar";
import IRepeatStrategyProto = space.kenko.proto.IRepeatStrategyProto;

type Props = {

  // The day of week of the event; this is used to initialize the default repeat
  // day on the repeat strategy
  firstDay: Date,

  proto: IRepeatStrategyProto
  onChange: (strategy: RepeatStrategyProto) => void;
}

export const RepeatStrategyEditor = (props: Props) => {

  // this is a string that's either 'none' or 'weekly' according to the oneof case
  const [strategy, setStrategy] = useState<string | undefined>(
      (props.proto as RepeatStrategyProto).RepeatStrategy ?? 'none');


  return <div>

    <LabeledDropdownInput label={'Repeat Strategy'}
                          options={[{
                            value: 'none',
                            label: 'Does not repeat'
                          }, {
                            value: 'weekly',
                            label: 'Weekly'
                          }
                          ]}
                          value={strategy}
                          error={''}
                          onChange={v => {
                            if (v == 'none') {
                              props.onChange(new RepeatStrategyProto({
                                none: {}
                              }))
                            } else if (v == 'weekly') {
                              props.onChange(new RepeatStrategyProto({
                                weekly: {
                                  repeatPeriodWeeks: 1,
                                  daysOfWeekBitmask: toBitMask(toDayOfWeek(props.firstDay))
                                }
                              }))
                            }

                            setStrategy(v);
                          }}/>

    {props.proto.weekly &&
        <WeeklyEditor proto={props.proto.weekly}
                      onProtoChange={newProto => props.onChange(new RepeatStrategyProto({
                        weekly: newProto
                      }))}
                      initialDayOfWeek={DayOfWeekProto.MONDAY}/>
    }

  </div>
}

// Returns the value in the week bitmask of the given day
const toBitMask = (day: DayOfWeekProto): number => {
  if (day == 0) {
    throw new Error("why is day 0");
  }
  console.log('day of week', DayOfWeekProto[day]);
  console.log('encoded', (1 << (day - 1)))
  return (1 << (day - 1));
}

// javascript and ISO are different/
// weirdly 1-6 are the same (MONDAY - SUNDAY) but in javascript's
// date 0 is sunday, vs ISO where 7 is sunday.
const toDayOfWeek = (date: Date): DayOfWeekProto => {
  if (date.getDay() == 0) {
    return DayOfWeekProto.SUNDAY;
  }

  return date.getDay();
}


type WeeklyEditorProps = {
  initialDayOfWeek: DayOfWeekProto
  proto: IWeeklyRepeatSchedule,
  onProtoChange: (proto: IWeeklyRepeatSchedule) => void,
}

const WeeklyEditor = (props: WeeklyEditorProps) => {


  // This is the enumeration order
  const dayOfWeekValues = [
    DayOfWeekProto.SUNDAY,
    DayOfWeekProto.MONDAY,
    DayOfWeekProto.TUESDAY,
    DayOfWeekProto.WEDNESDAY,
    DayOfWeekProto.THURSDAY,
    DayOfWeekProto.FRIDAY,
    DayOfWeekProto.SATURDAY
  ];

  return <div>

    <div className={'WeeklyRepeatPeriod'}>
      Repeat every....
      <input type={'number'}
             value={props.proto.repeatPeriodWeeks || 1}
             onChange={event => props.onProtoChange({
               ...props.proto,
               repeatPeriodWeeks: parseInt(event.target.value)
             })}
      />
      Week(s)
    </div>

    <div className={'WeeklyRepeatContainer'}>
      {dayOfWeekValues.map(value => {
        
        // value is the actual ISO value according to the proto (MONDAY = 1, SUNDAY = 7)
        // name is SUNDAY, MONDAY
        let name : string = DayOfWeekProto[value];

        console.log('Hear ye: ', value, name);
        return <span className={'WeeklyRepeatItem'}>
              <input type={'checkbox'}
                     checked={((props.proto.daysOfWeekBitmask || 0) & toBitMask(value)) > 0}
                     onChange={event => props.onProtoChange({
                       ...props.proto,
                       daysOfWeekBitmask: (props.proto.daysOfWeekBitmask || 0) ^ toBitMask(value)
                     })}
              />{name.substring(0, 1)}
            </span>;
      })}
    </div>
  </div>;


}